import React from "react";
import useApi from "storybook-dashboard/utils/fetching";
import IndicatorsBarChart from "storybook-dashboard/dashboard/charts/verticalBar";
import Im from "immutable";
import TipsSpinner from "AppSrc/utils/spinners/tipsSpinner";
import { chartColors } from "storybook-dashboard/dashboard/charts/utils";

const INDICATORS = Im.Set([
  "Total emissions (tCO2e)",
  "Annual total emissions per £1m turnover (tCO2e / GBP)",
  "Total emissions (tCO2e) / £1m turnover",
]);

export default function EmissionsLineChart({ projectId, refId, total = true }) {
  let { data, isLoading } = useApi(`/api/dashboard/project/${projectId}/wpack/${refId}/rags`);

  let indicators = data
    ?.get("indicators")
    ?.map((data, id) => data.set("id", id))
    ?.toList()
    ?.filter((i) => INDICATORS.has(i.get("name")))
    ?.map((i) => i.set("uid", i.get("uid") || i.get("id")));

  const emissionsPerTurnover = indicators?.filter((i) => i.get("name") !== "Total emissions (tCO2e)");
  const totalEmissions = indicators?.filter((i) => i.get("name") === "Total emissions (tCO2e)");

  let indicator = total ? totalEmissions : emissionsPerTurnover;
  let lineColor = total ? chartColors[0] : chartColors[1];

  if (isLoading) return <TipsSpinner />;
  if (!indicators || !indicators.size) return null;

  return (
    <IndicatorsBarChart
      projectId={projectId}
      refId={refId}
      indicators={indicator}
      filters={Im.Map()}
      period="year"
      chartType="line"
      color={lineColor}
    />
  );
}
